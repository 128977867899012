import { Injectable, signal } from '@angular/core';
import { BehaviorSubject, catchError, Observable, of, switchMap, tap, throwError } from 'rxjs';
import {
  DateRangeEvent,
  DateRangePresetType,
  PaginatedList,
  ProcessingState,
  Query,
  Tab,
} from '@services/core-services/models';
import { OrderService } from '@services/order-services/order.service';
import { Conditioner, FilterGroupType, Operator } from '@services/core-services/filters';
import { Orders } from '@services/order-services/models';
import { CustomerService } from '@services/customer-services/customer.service';
import { CustomerId } from '@services/customer-services/models/customer-id';
import { CustomerDetail } from '@services/customer-services/models';
import { PaymentService } from '@services/payment-services/payments.service';
import { Payment, Payments } from '@services/payment-services/models';
import { ErrorHandlerService } from '@services/core-services/error-handler.service';
import { LoyaltyService } from '@services/loyalty-services/loyalty.service';
import { LoyaltyPointActivity } from '@services/loyalty-services/models/loyaltiy-activity';

import { NotifyService } from '@services/core-services/notify.service';
import { FormErrorsService } from '@services/form-services/form-errors.service';
import { KycDocument } from '@services/customer-services/models/kyc';
import { LayoutService } from '@services/ui-services/layout.service';
import { KycDetail } from '@services/customer-services/models/kyc-detail';
import { DocumentStatusTypes } from '@services/customer-services/models/document-status.enum';
import { CustomerAttribute } from '@services/customer-services/models/attributes';
import { AttributeId } from '@services/attributes-service/models';
import { CustomerActivity } from '@services/customer-services/models/activities';
import { LoyaltyPrograms } from '@services/loyalty-services/models/loyaltiy-programs';
import { Product } from '@services/customer-services/models/customer-products';
import { AbandonedCart } from '@services/customer-services/models/abandoned-carts';
import { PaymentAction } from '@services/payment-services/models/payment-action';
import { PaymentId } from '@services/payment-services/models/payment-id';
import {
  PaymentAllowedActions,
  PaymentAllowedActionsLabel,
} from '@services/payment-services/models/payment-alowed-actions.enum';
import {
  AnalyticsInfo,
  AnalyticsInterval,
  type AnalyticsRangeContext,
  ComparePeriod,
} from '@services/analytics-services/core';
import { DateRangeService } from '@enginuity/core/molecules/date-range/date-range.service';
import { CustomerAnalyticsService, CustomerStats } from '@services/analytics-services/customers';
import { OrderAnalyticsService, OrderStats } from '@services/analytics-services/orders';
import { PaymentAnalyticsService, PaymentStats } from '@services/analytics-services/payment';
import { ModalService } from '@services/core-services/modal.service';

const TABS: Tab[] = [
  {
    label: 'Overview',
    id: 'overview',
  },
  {
    label: 'Orders',
    id: 'orders',
  },
  {
    label: 'Payments',
    id: 'payments',
  },
  {
    label: 'Loyalty',
    id: 'loyalty',
  },
  {
    label: 'KYC',
    id: 'kyc',
  },
  //TODO: {
  //   label: 'Subscriptions',
  //   id: 'subscriptions',
  // },
  //TODO: {
  //   label: 'Campaigns',
  //   id: 'campaigns',
  // },
];

@Injectable()
export class CustomerDetailsStore {
  public errorMessage = new BehaviorSubject<any>('');
  private selectedLoyalty = new BehaviorSubject<LoyaltyPointActivity | undefined>(undefined);
  protected customerLoyaltyLoader = new BehaviorSubject<ProcessingState>(ProcessingState.Loading);
  protected CustomerOrderLoader = new BehaviorSubject<ProcessingState>(ProcessingState.Loading);
  protected CustomerPaymentLoader = new BehaviorSubject<ProcessingState>(ProcessingState.Loading);
  protected CustomerKycLoader = new BehaviorSubject<ProcessingState>(ProcessingState.Loading);
  private kycDetailsOpen = new BehaviorSubject<boolean>(false);
  private selectedDocument = new BehaviorSubject<KycDetail | undefined>(undefined);
  public attributes: CustomerAttribute[] = [];
  public products = new BehaviorSubject<Product[] | undefined>(undefined);
  public abandoned_carts = new BehaviorSubject<AbandonedCart[] | undefined>(undefined);
  private paymentDetailOverlay = new BehaviorSubject<boolean>(false);
  private customer = new BehaviorSubject<CustomerDetail | undefined>(undefined);
  private selectedPayment = new BehaviorSubject<Payment | undefined>(undefined);
  private paymentActions = new BehaviorSubject<PaymentAction[]>([]);
  private orders = new BehaviorSubject<PaginatedList<Orders[]>>({
    list: [],
    pagination: undefined,
  });
  private loyalty_programs = new BehaviorSubject<PaginatedList<LoyaltyPrograms[]>>({
    list: [],
    pagination: undefined,
  });
  private payments = new BehaviorSubject<PaginatedList<Payments[]>>({
    list: [],
    pagination: undefined,
  });
  private activities = new BehaviorSubject<PaginatedList<LoyaltyPointActivity[]>>({
    list: [],
    pagination: undefined,
  });
  private customerActivities = new BehaviorSubject<PaginatedList<CustomerActivity[]>>({
    list: [],
    pagination: undefined,
  });
  private documents = new BehaviorSubject<PaginatedList<KycDocument[]>>({
    list: [],
    pagination: undefined,
  });
  private ordersQuery = new BehaviorSubject<Query>({
    filters: { filters: [], condition: Conditioner.AND },
    page: 1,
    limit: 10,
  });

  private tabs = new BehaviorSubject<Tab[]>(TABS);
  private context = new BehaviorSubject<AnalyticsRangeContext>({
    ...this.getDefaultRange(DateRangePresetType.AllTime),
    interval: AnalyticsInterval.Day,
  });
  private comparePeriod = new BehaviorSubject<ComparePeriod>(ComparePeriod.Custom);
  private customerAnalytics = new BehaviorSubject<AnalyticsInfo<CustomerStats> | undefined>(
    undefined
  );
  private orderAnalytics = new BehaviorSubject<AnalyticsInfo<OrderStats> | undefined>(undefined);
  private paymentAnalytics = new BehaviorSubject<AnalyticsInfo<PaymentStats> | undefined>(
    undefined
  );

  public bankingUpdated = signal<boolean>(false);

  constructor(
    private dateRangeService: DateRangeService,
    private readonly customerService: CustomerService,
    private readonly ordersService: OrderService,
    private readonly paymentService: PaymentService,
    private readonly loyaltyService: LoyaltyService,
    private errorHandlerService: ErrorHandlerService,
    private readonly notify: NotifyService,
    private formErrorService: FormErrorsService,
    private readonly layoutService: LayoutService,
    private customerAnalyticsService: CustomerAnalyticsService,
    private orderAnalyticsService: OrderAnalyticsService,
    private paymentAnalyticsService: PaymentAnalyticsService,
    private readonly modalService: ModalService
  ) {}

  getDefaultRange(preset: DateRangePresetType): DateRangeEvent {
    const { range, compare } = this.dateRangeService.getDateRangeContext(
      DateRangePresetType.AllTime
    );
    return {
      range,
      compare,
      preset,
    };
  }

  getCustomerOrderLoader(): Observable<ProcessingState> {
    return this.CustomerOrderLoader.asObservable();
  }

  getCustomerLoyaltyLoader(): Observable<ProcessingState> {
    return this.customerLoyaltyLoader.asObservable();
  }

  getCustomerPaymentLoader(): Observable<ProcessingState> {
    return this.CustomerPaymentLoader.asObservable();
  }

  getCustomerKycLoader(): Observable<ProcessingState> {
    return this.CustomerKycLoader.asObservable();
  }

  isDocumentDetailsOpen(): Observable<boolean> {
    return this.kycDetailsOpen.asObservable();
  }

  getSelectedDocument() {
    return this.selectedDocument.asObservable();
  }

  getSelectedLoyalty() {
    return this.selectedLoyalty.asObservable();
  }

  getCustomer() {
    return this.customer.asObservable();
  }

  getCustomerActivity() {
    return this.customerActivities.asObservable();
  }

  getCustomerAnalytics() {
    return this.customerAnalytics.asObservable();
  }

  getOrderAnalytics() {
    return this.orderAnalytics.asObservable();
  }

  getPaymentAnalytics() {
    return this.paymentAnalytics.asObservable();
  }

  getOrders() {
    return this.orders.asObservable();
  }
  getPayment() {
    return this.payments.asObservable();
  }

  getComparePeriod() {
    return this.comparePeriod.asObservable();
  }

  getRangeSnapshot() {
    return this.context.getValue();
  }

  setRange(range: AnalyticsRangeContext) {
    return this.context.next(range);
  }

  getRange() {
    return this.context.asObservable();
  }

  getLoyaltyProgram() {
    return this.loyalty_programs.asObservable();
  }

  getOrdersQuery() {
    return this.ordersQuery.asObservable();
  }

  getLoyaltyActivity() {
    return this.activities.asObservable();
  }

  getCustomerProduct() {
    return this.products.asObservable();
  }

  getPaymentActions() {
    return this.paymentActions.asObservable();
  }

  getCustomerDocuments() {
    return this.documents.asObservable();
  }

  ispaymentDetailOverlay(): Observable<boolean> {
    return this.paymentDetailOverlay.asObservable();
  }

  getSelectedPayment() {
    return this.selectedPayment.asObservable();
  }

  paginateLoyalty(page: number) {
    return this.loadCustomerLoyalty({ limit: 10, page });
  }

  getTabs() {
    return this.tabs.asObservable();
  }

  ModalOpen(ref: any) {
    this.modalService.open(ref);
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  getPaymentDetails(paymentId: string): Observable<Payment> {
    return this.paymentService.paymentDetail(paymentId);
  }

  loadCustomerAnalytics() {
    const customer = this.customer.getValue();
    const context = this.context.getValue();
    const { range, compare, interval, preset } = context;
    return this.customerAnalyticsService
      .getAnalytics({
        preset: preset as DateRangePresetType,
        range,
        compare,
        interval,
        customer_id: customer?.id,
      })
      .pipe(tap(analytics => this.customerAnalytics.next(analytics)));
  }

  loadPaymentAnalytics() {
    const customer = this.customer.getValue();
    const context = this.context.getValue();
    const { range, compare, interval, preset } = context;
    return this.paymentAnalyticsService
      .getAnalytics({
        preset: preset as DateRangePresetType,
        range,
        compare,
        interval,
        user_id: customer?.id,
      })
      .pipe(tap(analytics => this.paymentAnalytics.next(analytics)));
  }

  loadCustomerDetailAndAnalytics(customerId: string): Observable<any> {
    return this.customerService.getCustomerDetail(customerId).pipe(
      tap(customer => {
        this.customer.next(customer);
      }),
      switchMap(customer => {
        if (!customer?.id) {
          return of(null);
        }
        return this.loadOrderAnalytics();
      }),
      tap(analytics => analytics)
    );
  }

  loadOrderAnalytics(): Observable<any> {
    const customer = this.customer.getValue();
    const context = this.context.getValue();
    const { range, compare, interval, preset } = context;

    if (!customer?.id) {
      return of(null);
    }

    const payload = {
      preset: preset as DateRangePresetType,
      range,
      compare,
      interval,
      customer_id: customer.id,
    };

    return this.orderAnalyticsService.getAnalytics(payload).pipe(
      catchError(error => {
        const errorMessage = this.formErrorService.getErrorMsg(error);
        this.notify.error(`Error: ${errorMessage}`);
        return throwError(error);
      }),
      tap(analytics => {
        this.orderAnalytics.next(analytics);
      })
    );
  }

  loadCustomerDetail(id: CustomerId) {
    return this.customerService
      .getCustomerDetail(id)
      .pipe(tap(customer => this.customer.next(customer)));
  }

  acceptKyc() {
    const documentId = this.selectedDocument.getValue()?.id;
    const customerId = this.customer.getValue()?.id;
    if (!customerId) {
      return throwError('Customer ID not found');
    }
    if (!documentId) {
      return throwError('Document ID not found');
    }
    const documentData = {
      status: DocumentStatusTypes.Approved, // Replace with a valid value from DocumentStatusTypes
      // Add other fields if necessary
    };
    return this.customerService.updateDocumentDetail(customerId, documentId, documentData).pipe(
      tap(response => {
        this.getKycDetails(customerId, documentId).subscribe();
        this.notify.success('Document successfully accepted');
      }),
      catchError(error => {
        const errorMessage = this.formErrorService.getErrorMsg(error); // Use getErrorMsg for server-side errors
        this.notify.error(`Failed to update KYC document: ${errorMessage}`);
        return throwError(error); // Rethrow the error to maintain observable chain
      })
    );
  }

  createBankAccount(formValue: any) {
    const customerId = this.customer.getValue()?.id;
    if (!customerId) {
      return throwError('Customer ID not found');
    }

    return this.customerService.createBankAccount(customerId, formValue).pipe(
      tap(response => {
        this.loadCustomerDetail(customerId).subscribe();
        this.notify.success('Bank account created successfully');
        this.bankingUpdated.set(true);
        setTimeout(() => this.bankingUpdated.set(false), 100);
      }),
      catchError(error => {
        const errorMessage = this.formErrorService.getErrorMsg(error);
        this.notify.error(`Failed to create bank account: ${errorMessage}`);
        return throwError(error);
      })
    );
  }

  addNewAttribute(newAttribute: any) {
    const customerId = this.customer.getValue()?.id;
    if (!customerId) {
      return throwError('Customer ID not found'); // Return an observable that emits an error
    }

    return this.customerService.createCustomerAttribute(customerId, newAttribute).pipe(
      tap(response => {
        this.loadCustomerDetail(customerId).subscribe(); // Refresh customer details
        this.notify.success('Attribute created successfully');
        this.attributes.push(newAttribute); // Add the new attribute to the list
      }),
      catchError(error => {
        const errorMessage = this.formErrorService.getErrorMsg(error); // Use getErrorMsg for server-side errors
        this.notify.error(`Failed to create attribute: ${errorMessage}`);
        return throwError(error); // Rethrow the error to maintain observable chain
      })
    );
  }

  addNewPoints(newPoints: any) {
    const customerId = this.customer.getValue()?.id;
    if (!customerId) {
      return throwError('Customer ID not found'); // Return an observable that emits an error
    }

    return this.loyaltyService.addLoyaltyPoints(customerId, newPoints).pipe(
      tap(response => {
        this.loadCustomerDetail(customerId).subscribe(); // Refresh customer details
        this.notify.success('Points added');
        this.attributes.push(newPoints); // Add the new attribute to the list
      }),
      catchError(error => {
        const errorMessage = this.formErrorService.getErrorMsg(error); // Use getErrorMsg for server-side errors
        this.notify.error(`add loyalty points: ${errorMessage}`);
        return throwError(error); // Rethrow the error to maintain observable chain
      })
    );
  }

  createCustomerAddress(formValue: any) {
    const customerId = this.customer.getValue()?.id;
    if (!customerId) {
      return throwError('Customer ID not found');
    }

    return this.customerService.createCustomerAddress(customerId, formValue).pipe(
      tap(response => {
        this.loadCustomerDetail(customerId).subscribe();
        this.notify.success('Added new address successfully');
      }),
      catchError(error => {
        const errorMessage = this.formErrorService.getErrorMsg(error); // Use getErrorMsg for server-side errors
        this.notify.error(`Failed to add new address account: ${errorMessage}`);
        return throwError(error); // Rethrow the error to maintain observable chain
      })
    );
  }

  deleteBank(bankId: string) {
    const customerId = this.customer.getValue()?.id;
    if (!customerId) {
      return throwError('Customer ID not found');
    }

    return this.customerService.deleteBank(customerId, bankId).pipe(
      tap(() => {
        this.loadCustomerDetail(customerId).subscribe();
        this.notify.success('Bank account deleted successfully');
        this.bankingUpdated.set(true);
        setTimeout(() => this.bankingUpdated.set(false), 100);
      }),
      tap(_ => setTimeout(() => this.modalService.dismissAll(), 1000)),
      catchError(error => {
        const errorMessage = this.formErrorService.getErrorMsg(error);
        this.notify.error(`Failed to delete bank account: ${errorMessage}`);
        return throwError(error);
      })
    );
  }

  deleteAttribute(attributeId: AttributeId) {
    const customerId = this.customer.getValue()?.id;
    if (!customerId) {
      return throwError('Customer ID not found');
    }

    return this.customerService.deleteCustomerAttribute(customerId, attributeId).pipe(
      tap(() => {
        this.notify.success('deleted customer attribute value');
      }),
      tap(_ => setTimeout(() => this.modalService.dismissAll(), 1000)),
      catchError(error => {
        const errorMessage = this.formErrorService.getErrorMsg(error); // Use getErrorMsg for server-side errors
        this.notify.error(`Failed to delete customer attribute value: ${errorMessage}`);
        return throwError(error); // Rethrow the error to maintain observable chain
      })
    );
  }

  deleteAddress(addressId: string) {
    const customerId = this.customer.getValue()?.id;
    if (!customerId) {
      return throwError('Customer ID not found');
    }

    return this.customerService.deleteAddress(customerId, addressId).pipe(
      tap(() => {
        this.loadCustomerDetail(customerId).subscribe();
        this.notify.success('Customer Address deleted successfully');
      }),
      tap(_ => setTimeout(() => this.modalService.dismissAll(), 1000)),
      catchError(error => {
        const errorMessage = this.formErrorService.getErrorMsg(error); // Use getErrorMsg for server-side errors
        this.notify.error(`Failed to delete address: ${errorMessage}`);
        return throwError(error); // Rethrow the error to maintain observable chain
      })
    );
  }

  // loadCustomerOrders() {
  //   this.CustomerOrderLoader.next(ProcessingState.Loading);
  //   const customer = this.customer.getValue();
  //   const query = this.ordersQuery.getValue();
  //   query.filters?.filters?.push({
  //     field: 'user_id',
  //     value: customer!.id,
  //     type: FilterGroupType.Data,
  //     operator: Operator.Equals,
  //   });

  //   return this.ordersService.getAllOrderData(query).pipe(
  //     tap(orders => {
  //       this.CustomerOrderLoader.next(ProcessingState.Success);
  //       this.orders.next(orders);
  //     }),
  //     catchError(error => {
  //       this.CustomerOrderLoader.next(ProcessingState.Error);
  //       const errorMessage = this.errorHandlerService.handleServerError(error);
  //       this.errorMessage.next(errorMessage);
  //       // this.errorMessage.next(error || 'An error occurred');
  //       return [];
  //     })
  //   );
  // }

  loadCustomerOrders() {
    this.CustomerOrderLoader.next(ProcessingState.Loading);
    const customer = this.customer.getValue();
    const query = this.ordersQuery.getValue();
    const context = this.context.getValue();

    if (!query.filters) {
      query.filters = { condition: Conditioner.AND, filters: [] };
    }

    if (!query.filters.filters) {
      query.filters.filters = [];
    }
    query.filters.filters = query.filters.filters.filter(
      filter => 'field' in filter && !['user_id', 'created_at'].includes(filter.field)
    );
    query.filters?.filters?.push({
      field: 'user_id',
      value: customer!.id,
      type: FilterGroupType.Data,
      operator: Operator.Equals,
    });

    if (context.range) {
      query.filters?.filters?.push({
        field: 'created_at',
        value: [context.range.start, context.range.end],
        type: FilterGroupType.Data,
        operator: Operator.Between,
      });
      /*query.filters?.filters?.push({
        field: 'created_at',
        value: context.range.end,
        type: FilterGroupType.Data,
        operator: Operator.LessThanOrEqualTo,
      });*/
    }

    return this.ordersService.getAllOrderData(query).pipe(
      tap(orders => {
        this.CustomerOrderLoader.next(ProcessingState.Success);
        this.orders.next(orders);
      }),
      catchError(error => {
        this.CustomerOrderLoader.next(ProcessingState.Error);
        const errorMessage = this.errorHandlerService.handleServerError(error);
        this.errorMessage.next(errorMessage);
        return [];
      })
    );
  }

  loadCustomerLoyaltyProgram() {
    this.CustomerOrderLoader.next(ProcessingState.Loading);
    const query = this.ordersQuery.getValue();
    const context = this.context.getValue();

    if (!query.filters) {
      query.filters = { condition: Conditioner.AND, filters: [] };
    }

    if (!query.filters.filters) {
      query.filters.filters = [];
    }
    query.filters.filters = query.filters.filters.filter(
      filter => 'field' in filter && !['user_id', 'created_at'].includes(filter.field)
    );

    query.filters?.filters?.push({
      field: 'status',
      value: 'active',
      type: FilterGroupType.Data,
      operator: Operator.Equals,
    });

    if (context.range) {
      query.filters?.filters?.push({
        field: 'created_at',
        value: context.range.start,
        type: FilterGroupType.Data,
        operator: Operator.GreaterThanOrEqualTo,
      });
      query.filters?.filters?.push({
        field: 'created_at',
        value: context.range.end,
        type: FilterGroupType.Data,
        operator: Operator.LessThanOrEqualTo,
      });
    }

    return this.loyaltyService.getCustomerLoyaltyProgram(query).pipe(
      tap(loyalty_programs => {
        this.CustomerOrderLoader.next(ProcessingState.Success);
        this.loyalty_programs.next(loyalty_programs);
      }),
      catchError(error => {
        this.CustomerOrderLoader.next(ProcessingState.Error);
        const errorMessage = this.errorHandlerService.handleServerError(error);
        this.errorMessage.next(errorMessage);
        // this.errorMessage.next(error || 'An error occurred');
        return [];
      })
    );
  }

  loadCustomerPayments() {
    this.CustomerPaymentLoader.next(ProcessingState.Loading);
    const customer = this.customer.getValue();
    const query = this.ordersQuery.getValue();
    const context = this.context.getValue();
    if (!query.filters) {
      query.filters = { condition: Conditioner.AND, filters: [] };
    }

    if (!query.filters.filters) {
      query.filters.filters = [];
    }
    query.filters.filters = query.filters.filters.filter(
      filter => 'field' in filter && !['user_id', 'created_at'].includes(filter.field)
    );
    query.filters?.filters?.push({
      field: 'user_id',
      value: customer!.id,
      type: FilterGroupType.Data,
      operator: Operator.Equals,
    });
    if (context.range) {
      query.filters?.filters?.push({
        field: 'created_at',
        value: context.range.start,
        type: FilterGroupType.Data,
        operator: Operator.GreaterThanOrEqualTo,
      });
      query.filters?.filters?.push({
        field: 'created_at',
        value: context.range.end,
        type: FilterGroupType.Data,
        operator: Operator.LessThanOrEqualTo,
      });
    }
    return this.paymentService.getAllPaymentData(query).pipe(
      tap(payments => {
        this.CustomerPaymentLoader.next(ProcessingState.Success);
        this.payments.next(payments);
      }),
      catchError(error => {
        this.CustomerPaymentLoader.next(ProcessingState.Error);
        const errorMessage = this.errorHandlerService.handleServerError(error);
        this.errorMessage.next(errorMessage);
        // this.errorMessage.next(error || 'An error occurred');
        return [];
      })
    );
  }

  openPaymentDetails(paymentId: PaymentId, open?: boolean) {
    const isOpen = this.paymentDetailOverlay.getValue();
    const flag: boolean = open === undefined || open === null ? !isOpen : open;
    this.layoutService.toggleLayoutOverlay(flag);
    this.paymentDetailOverlay.next(flag);
    if (flag) {
      this.getPaymentDetails(paymentId)
        .pipe(tap(payment => this.paymentActions.next(this.mapPaymentActions(payment))))
        .subscribe(payment => {
          this.selectedPayment.next(payment);
        });
    }
  }

  private mapPaymentActions(payment: Payment): PaymentAction[] {
    const allowedActions = payment.allowed_actions || [];
    if (allowedActions.length === 0) {
      // If there are no actions available, return an empty array
      return [];
    }
    return allowedActions.map((action: string) => {
      let name = '';
      let actionType = '';
      let icon = '';
      let color = '';

      // Determine action details based on action name
      switch (action) {
        case PaymentAllowedActions.CancelPayment:
          name = PaymentAllowedActionsLabel.CancelPayment;
          actionType = PaymentAllowedActions.CancelPayment;
          icon = 'block';
          color = 'red';
          break;
        case PaymentAllowedActions.RefundPayment:
          name = PaymentAllowedActionsLabel.RefundPayment;
          actionType = PaymentAllowedActions.RefundPayment;
          icon = 'refund';
          color = 'default';
          break;
        case PaymentAllowedActions.SendReminder:
          name = PaymentAllowedActionsLabel.SendReminder;
          actionType = PaymentAllowedActions.SendReminder;
          icon = 'email';
          color = 'default';
          break;
        default:
          break;
      }

      // Return the action object
      return {
        name: name,
        icon: icon,
        color: color,
        checkbox: false,
        radio: false,
        toggle: false,
        showIcon: false,
        action: actionType,
      };
    });
  }

  fetchActivities(customerId: CustomerId) {
    const pagination = this.customerActivities.getValue().pagination;
    const nextPage = pagination ? pagination.next_page : 1;

    const query: Query = { page: nextPage };
    this.customerService
      .getActivity(customerId, query)
      .pipe(
        tap(({ list, pagination }) => {
          const currentActivities = this.customerActivities.getValue().list || [];
          const updatedActivities = [...currentActivities, ...list];
          this.customerActivities.next({
            list: updatedActivities,
            pagination: pagination,
          });
        }),
        catchError(error => {
          console.error(error);
          return of({ list: [], pagination: { next_page: null } });
        })
      )
      .subscribe();
  }

  loadCustomerLoyalty(query?: Query) {
    this.customerLoyaltyLoader.next(ProcessingState.Success);
    query = query || {};
    query.limit = query.limit || 10;
    const customer = this.customer.getValue();
    return this.loyaltyService.getLoyaltyPointActivity(customer?.id!, query).pipe(
      tap(activities => {
        this.customerLoyaltyLoader.next(ProcessingState.Success);
        this.activities.next(activities);
      }),
      catchError(error => {
        this.customerLoyaltyLoader.next(ProcessingState.Error);
        const errorMessage = this.errorHandlerService.handleServerError(error);
        this.errorMessage.next(errorMessage);
        // this.errorMessage.next(error || 'An error occurred');
        return [];
      })
    );
  }

  loadCustomerProducts(query?: Query) {
    this.customerLoyaltyLoader.next(ProcessingState.Success);
    const customer = this.customer.getValue();
    const q: any = query;
    q['customer_id'] = customer?.id;

    return this.customerService.getCustomerProducts(q).pipe(
      tap(products => {
        this.customerLoyaltyLoader.next(ProcessingState.Success);
        this.products.next(products);
      }),
      catchError(error => {
        this.customerLoyaltyLoader.next(ProcessingState.Error);
        const errorMessage = this.errorHandlerService.handleServerError(error);
        this.errorMessage.next(errorMessage);
        // this.errorMessage.next(error || 'An error occurred');
        return [];
      })
    );
  }

  loadAbandonedCart(query?: Query) {
    this.customerLoyaltyLoader.next(ProcessingState.Success);
    const customer = this.customer.getValue();
    const q: any = query;
    console.log(query, q);
    q['customer_id'] = customer?.id;

    return this.customerService.getAbondandCarts(q).pipe(
      tap(abandoned_carts => {
        this.customerLoyaltyLoader.next(ProcessingState.Success);
        this.abandoned_carts.next(abandoned_carts);
      }),
      catchError(error => {
        this.customerLoyaltyLoader.next(ProcessingState.Error);
        const errorMessage = this.errorHandlerService.handleServerError(error);
        this.errorMessage.next(errorMessage);
        // this.errorMessage.next(error || 'An error occurred');
        return [];
      })
    );
  }

  loadCustomerKyc(query?: Query) {
    this.CustomerKycLoader.next(ProcessingState.Success);
    query = query || {};
    query.limit = query.limit || 10;
    const customer = this.customer.getValue();
    return this.customerService.getCustomerKyc(customer?.id!, query).pipe(
      tap(documents => {
        this.CustomerKycLoader.next(ProcessingState.Success);
        this.documents.next(documents);
      }),
      catchError(error => {
        this.CustomerKycLoader.next(ProcessingState.Error);
        const errorMessage = this.errorHandlerService.handleServerError(error);
        this.errorMessage.next(errorMessage);
        // this.errorMessage.next(error || 'An error occurred');
        return [];
      })
    );
  }

  getKycDetails(customerId: string, documentId: string): Observable<KycDetail> {
    return this.customerService.getDocumentDetail(customerId, documentId);
  }

  toggleDocumentDetails(customerId: string, documentId: string, open?: boolean) {
    const isOpen = this.kycDetailsOpen.getValue();
    const flag: boolean = open === undefined || open === null ? !isOpen : open;
    this.layoutService.toggleLayoutOverlay(flag);
    this.kycDetailsOpen.next(flag);
    if (flag) {
      this.getKycDetails(customerId, documentId).subscribe(document => {
        this.selectedDocument.next(document);
      });
    }
  }

  notifyBankingUpdated() {
    this.bankingUpdated.set(true);
    setTimeout(() => this.bankingUpdated.set(false), 100);
  }
}
